import React, { useContext } from 'react'
import Proptypes from 'prop-types'
import { Select } from '@gk-devteam/apmc-core-web'

import { ConstructioContext } from '../../../Routes/RouteBlocks/yasue/ConstructionWorkBlock'

export default function Contract ({ constructionWorkID, disabled }) {
  const { contracts, loading } = useContext(ConstructioContext)

  return (
    <Select
      name="contract_id"
      label='yasue:contracts.contract_manage_id'
      flex={1}
      size="XL"
      options={contracts}
      loading={loading}
      disabled={constructionWorkID !== undefined || disabled}
      showSearch
      autocomplete="on"
      required
    />
  )
}

Contract.propTypes = {
  contracts: Proptypes.array,
  loading: Proptypes.bool,
  constructionWorkID: Proptypes.string,
  disabled: Proptypes.bool
}
