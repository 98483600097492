import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Table, useTranslation } from '@gk-devteam/apmc-core-web'

import { selectConstructionWorkData, selectConstructionWorkFilesData } from '../../../selectors'
import {
  DELETE_CONSTRUCTION_WORK_FILE,
  SET_ACTIVE_CONSTRUCTION_WORK_FILE,
  TOGGLE_ADD_CONSTRUCTION_WORK_FILE_MODAL,
  TOGGLE_VIEW_CONSTRUCTION_WORK_FILE_MODAL
} from '../../../types'

import PanelLayout from '../../../components/Display/Panels/PanelLayout'
import ViewConstructionWorkFileModal from './ViewConstructionWorkFileModal'
import AddConstructionWorkFileModal from './AddConstructionWorkFileModal'
import ContractInfo from '../../../forms/yasue/ContractInfo'

const ConstructionWorkFilesPage = ({ constructionWorkID }) => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [fileURL, setFileURL] = useState('')
  const [fileContentType, setFileContentType] = useState('')
  const [fileName, setFileName] = useState('')
  const data = useSelector(selectConstructionWorkData)

  const _openViewModal = (id, row, e) => {
    setFileURL(row.file_url)
    setFileContentType(row.content_type)
    setFileName(row.filename)
    dispatch({ type: TOGGLE_VIEW_CONSTRUCTION_WORK_FILE_MODAL })
    dispatch({ type: SET_ACTIVE_CONSTRUCTION_WORK_FILE, payload: { id } })
  }

  const _openAddModal = () => {
    dispatch({ type: TOGGLE_ADD_CONSTRUCTION_WORK_FILE_MODAL })
  }

  const breadcrumbs = [
    {
      label: 'navigation:yasue.construction_work_list',
      link: '/yasue/construction_works?sort_by=contract_date&sort_order=desc'
    },
    {
      label: 'navigation:yasue.construction_work_detail',
      link: `/yasue/construction_works/edit/${constructionWorkID}`
    },
    {
      label: 'navigation:yasue.construction_work_files',
      link: `/yasue/construction_works/edit/${constructionWorkID}/files`
    }
  ]

  const columns = [
    {
      title: '',
      dataIndex: 'filename',
      key: 'filename'
      // render: _renderTitle
    },
    {
      title: '',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('delete'),
          danger: true,
          onClick: (id, close) => {
            dispatch({ type: DELETE_CONSTRUCTION_WORK_FILE, constructionWorkID, constructionWorkFileID: id })
            close()
          }
        }
      ]
    }
  ]

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      title="navigation:yasue.construction_work_files"
      handleAddClick={_openAddModal}
      noLegend
    >
      <ContractInfo data={data} />
      <AddConstructionWorkFileModal constructionWorkID={constructionWorkID} />
      <ViewConstructionWorkFileModal fileURL={fileURL} fileContentType={fileContentType} fileName={fileName} />
      <Table
        fullHeight
        columns={columns}
        rowKey="id"
        dataSelector={selectConstructionWorkFilesData}
        onRowClick={_openViewModal}
        listMode
      />
    </PanelLayout>
  )
}

export default ConstructionWorkFilesPage

ConstructionWorkFilesPage.propTypes = {
  constructionWorkID: PropTypes.string.isRequired
}
