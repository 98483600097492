import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { CancelToken } from 'axios'
import { ItemNotFound, LeftNavigationMenu, PageLayout, selectPages, useTranslation } from '@gk-devteam/apmc-core-web'

import { selectConstructionWorkError, selectLoadingConstructionWork } from '../../../selectors'
import { FETCH_CONSTRUCTION_WORK, RESET_CONSTRUCTION_WORK } from '../../../types'
import { fetchContractList } from '../../../services'

import NotFoundPage from '../../../pages/NotFoundPage/NotFoundPage'

export const ConstructioContext = React.createContext()

const ConstructionWorkBlock = ({ enabled, children, constructionWorkID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['navigation'])
  const pages = useSelector(selectPages)
  const loading = useSelector(selectLoadingConstructionWork)
  const error = useSelector(selectConstructionWorkError)

  const [contractsLoading, setContractsLoading] = useState(false)
  const [contracts, setContracts] = useState(null)

  useEffect(() => {
    if (constructionWorkID) {
      dispatch({ type: FETCH_CONSTRUCTION_WORK, id: constructionWorkID })
    }
    return () => {
      dispatch({ type: RESET_CONSTRUCTION_WORK })
    }
  }, [dispatch, constructionWorkID])

  useEffect(() => {
    setContractsLoading(true)
    const fetchData = async () => {
      try {
        const cancelSource = CancelToken.source()
        const res = await fetchContractList(cancelSource)
        if (res && res.data) {
          const contractsOptions = res.data.results.map(contract => {
            const { id, contract_manage_id } = contract
            return {
              label: contract_manage_id,
              value: id,
              disabled: false
            }
          })
          setContracts(contractsOptions)
        }
        setContractsLoading(false)
      } catch (error) {
        console.warning('error', error)
        setContractsLoading(false)
      }
    }
    fetchData()
    return () => {
      // reset data
      setContractsLoading(false)
      setContracts([])
    }
  }, [])

  if (!constructionWorkID) return null
  if (!enabled || !children || !pages) return <NotFoundPage />

  const getMenu = () => {
    const menu = []
    menu.push({
      label: t('navigation:yasue.construction_work_detail'),
      link: './'
    })
    menu.push({
      label: t('navigation:yasue.construction_work_files'),
      link: './files'
    })

    return menu
  }

  return (
    <PageLayout
      sidebar={loading || (error && error.code === 404) ? null : () => <LeftNavigationMenu menu={getMenu()}/>}
      noMain
    >
      <ConstructioContext.Provider value={{ contracts, loading: contractsLoading }}>
        {
          loading
            ? null
            : error && error.code === 404
              ? <ItemNotFound item={t('navigation:yasue.construction_work')} link="/yasue/construction_works?sort_by=contract_date&sort_order=desc" />
              : children
        }
      </ConstructioContext.Provider>

    </PageLayout>
  )
}

export default ConstructionWorkBlock

ConstructionWorkBlock.propTypes = {
  enabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  constructionWorkID: PropTypes.string
}
