import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { CancelToken } from 'axios'
import { message } from 'antd'
import { PageLayout, useTranslation } from '@gk-devteam/apmc-core-web'

import { FETCH_CONSTRUCTION_WORK_SUCCESS, RESET_CONSTRUCTION_WORK } from '../../../types'
import { fetchConstructionWork } from '../../../services'

import ConstructionWorkForm from '../../../forms/yasue/ConstructionWorkForm/ConstructionWorkForm'

const initialData = {
  id: null,
  contract_id: null,
  delivery_date: null,
  completion_date: null,
  title: null,
  work_number: null,
  postcode: null,
  prefecture: null,
  city: null,
  address: null,
  memo: null,
  content: null
}

const ConstructionWorkAddPage = ({ location }) => {
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const prepareData = useCallback(
    (data) => {
      if (data && data.id) {
        const editedData = { ...data }
        delete editedData.id
        delete editedData.modified
        delete editedData.modifiedBy
        setFormData(editedData)
      } else if (location.state && location.state.constructionWorkID) {
        setFormData(data)
      } else {
        setFormData(data)
      }
      dispatch({ type: FETCH_CONSTRUCTION_WORK_SUCCESS, payload: null })
    },
    [dispatch, location.state]
  )

  const fetchData = useCallback(
    async () => {
      const cancelSource = CancelToken.source()
      let data = initialData
      if (location && location.state) {
        if (location.state.copy) {
          setLoading(true)
          try {
            const res = await fetchConstructionWork(location.state.id, cancelSource)
            setLoading(false)
            if (res && res.data) {
              data = res.data
            } else {
              message.error(t('fetch_error'))
            }
          } catch (error) {
            setLoading(false)
            message.error(t('fetch_error'))
          }
        }
      }
      if (location?.state?.contract) {
        data = {
          ...data,
          contract_id: location.state.contract?.contract_id
        }
      }
      prepareData(data)
    },
    [location, prepareData, t]
  )

  useEffect(() => {
    fetchData()
    return () => {
      dispatch({ type: RESET_CONSTRUCTION_WORK })
    }
  }, [location, dispatch, fetchData])

  const breadcrumbs = [
    {
      label: 'navigation:yasue.construction_work_list',
      link: '/yasue/construction_works?sort_by=contract_date&sort_order=desc'
    },
    {
      label: 'navigation:yasue.construction_work_add',
      link: '/yasue/construction_works/add'
    }
  ]

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      {
        formData
          ? <ConstructionWorkForm data={formData} lockContract={!!location?.state?.contract?.contract_id} />
          : null
      }
    </PageLayout>
  )
}

export default ConstructionWorkAddPage

ConstructionWorkAddPage.propTypes = {
  location: PropTypes.object.isRequired
}
